import SelectBox from "./elements/SelectBox";
import SelectRes from "./elements/SelectRes";
import InputText from "./elements/InputText";
import ColorInput from "./elements/ColorInput";
import GradientInput from "./elements/GradientInput";
import FontSize from "./elements/FontSize";
import FontWeight from "./elements/FontWeight";
import FontFamily from "./elements/FontFamily";
import TextareaInput from "./elements/TextareaInput";
import EditAll from "./elements/EditAll";
import SimpleList from "./elements/SimpleList";
import BGImage from "./elements/BGImage";
import ImageUploader from "./elements/ImgUploader";
import ListComponent from "./ListComponent";
import SubscriptionForm from "./SubscriptionForm";
import RichTextEditor from "./elements/RichTextEditor";

import * as CUSTOMCOMPS from "./custom";
import PaddingMargin from "./elements/PaddingMargin";
import { twdClass } from "../../constants/tailwind.constants";
import { RowPerColumns } from "./elements/RowPerColumns";

const RenderElements = ({ element, data, updateElementValue, inputArgs }) => {
  //if(element.hoverStyle==true) console.log("ELEMENT", element.label, element)
  if (element.inputType === "select") {
    return (
      <SelectBox
        element={element}
        data={data}
        updateElementValue={updateElementValue}
        inputArgs={inputArgs}
      />
    );
  } else if (element.inputType === "selectres") {
    return (
      <SelectRes
        element={element}
        data={data}
        updateElementValue={updateElementValue}
        inputArgs={inputArgs}
      />
    );
  }  else if (element.inputType === "text") {
    return (
      <InputText
        element={element}
        data={data}
        updateElementValue={updateElementValue}
        inputArgs={inputArgs}
      />
    );
  } else if (element.inputType === "textarea") {
    return (
      <TextareaInput
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "richtext") {
    return (
      <RichTextEditor
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "simplelist") {
    return (
      <SimpleList
        element={element}
        data={data}
        updateElementValue={updateElementValue}
        inputArgs={inputArgs}
      />
    );
  }else if (
    element.inputType === "color" ||    
    element.inputType === "backgroundcolor"
  ) {
    //if(element.styleStates==true) console.log("Color ELEMENT", element.label, element)
    return (
      <ColorInput
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (    
    element.inputType === "gradient"
  ) {
    return (
      <GradientInput
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "fontsize") {
    return (
      <FontSize
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "fontweight") {
    return (
      <FontWeight
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "fontfamily") {
    return (
      <FontFamily
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (
    element.inputType === "bgimage" ||
    element.inputType === "backgroundimage"
  ) {
    return (
      <BGImage
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (
    element.inputType === "padding" ||
    element.inputType === "margin"
  ) {
    return (
      <PaddingMargin
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "image") {
    return (
      <ImageUploader
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "list") {
    return (
      <ListComponent
        element={element}
        data={data}
        updateElementValue={updateElementValue}
        inputArgs={inputArgs}
      />
    );
  } else if (element.inputType === "columns") {
    return (
      <RowPerColumns
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "componentTag") {
    return (
      <SubscriptionForm
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  } else if (element.inputType === "custom") {
    return elementHOC(
      CUSTOMCOMPS[element.component],
      data,
      element,
      updateElementValue
    );
  } else if (element.inputType === "section") {
    return (
      <div
        className={twdClass(
          "pt-3 text-blue-500 border-t-2 border-blue-200 my-3"
        )}
      >
        {element.label}
      </div>
    );
  } else if (
    element.inputType === "editall"
  ) {
    //if(element.styleStates==true) console.log("Color ELEMENT", element.label, element)
    return (
      <EditAll
        element={element}
        data={data}
        updateElementValue={updateElementValue}
      />
    );
  }

  return <></>;
};

export default RenderElements;

const elementHOC = (Wrapper, data, element, updateElementValue) => {
  return (
    <Wrapper
      data={data}
      element={element}
      updateElementValue={updateElementValue}
    />
  );
};
