import { getDefaultFields } from "../common/common.fields";

export const TEXT_ELEMENT_FORM = {
  sectionName: "Text Section",
  elements: [
    {
      inputType: "list",
      label: "Text info",
      hasStyleProps: true,
      styleElementId: 1,
      stylesProps: {},
      updateTo: "texts.list",
      mockElements: {
        elements: [
          {
            inputType: "text",
            label: "text",
            defaultValue:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
            updateTo: "texts.list[i].text",
          },
        ],
        hasStyleProps: true,
        styleElementId: 2,
        stylesProps: {},
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Text section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin", "editall"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Text section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "texts.styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin", "editall"],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Text section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "texts.list[i].styleProps",
          fields: [
            "fontfamily",
            "fontsize",
            "fontweight",
            "textalign",
            "color",
            "padding",
            "margin",
            "editall"
          ],
        }),
      ],
    },
  ],
};
