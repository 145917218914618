import { useDispatch, useSelector } from "react-redux";
import ToggleBtn from "../../common/ToggleBtn";
import {
  CREATE_NEW_PAGE,
  UPDATE_PAGE_SETTINGS,
  UPDATE_PROJECT_INFO,
  SELECT_PAGE_ID,
  UPDATE_PROJECT_PAGE,
  RESET_POPUP,
} from "../../../constants/action.constants";
import { twdClass } from "../../../constants/tailwind.constants";
import { INPUT_TEXT, BTN_CLS } from "../../../constants/var.constants";
import PagesGoogleSheetInfo from "./PagesGoogleSheetInfo";
import { useState, useEffect } from "react";
import { set, cloneDeep, find } from "lodash";
import CustomSelectBox from "../../common/CustomSelectBox";
import { CONNECTIONS_METADATA } from "../connections/connection.metadata";
import { PageSupabasePanel } from "./PageSupabasePanel";
import {Button} from "../../ui/com_ui";
import { DynamicPageVs_play} from "../../content/visual_steps";
import { saveProject, getProjectInfo, getPageJSONData } from "../../../api/services";
import { toast } from "react-toastify";



const PagesSettingsPanel = ({saveSettings}) => {
  const dispatch = useDispatch();
  const {
    pageInfo,
    projectData: { project_id, connections, pages },
  } = useSelector((state) => state.studioDetails);
  

  const [settingsData, setSettingsData] = useState({});
  useEffect(() => {
    setSettingsData({ ...pageInfo.settings });
  }, [pageInfo]);
  
  const updateSettingsData = (to, value) => {
    const sData = cloneDeep(settingsData);
    const setting = set(sData, to, value);
    setSettingsData((data) => ({ ...data, ...setting }));
  };

  const [connectionType, setConnectionType] = useState("");
  useEffect(() => {
    if (settingsData && settingsData.connectionId) {
      const { connectionId } = settingsData;
      const vals = find(connections, ["connection_id", connectionId]);
      if(vals) setConnectionType(vals.connection_type);
    }
  }, [settingsData.connectionId]);


  /*
  useEffect(() => {
    //console.log("save trigger", settingsData.pageName, pageInfo)
    if (pageInfo.settings.pageName === settingsData.pageName) {
      //console.log("save compare", settingsData.pageName, pageInfo)
      if(!pageInfo.page_id){
        //savePage("save")
      }else{
        //savePage("update")   
      }      
    }else if (pageInfo.settings.pageSlug === settingsData.pageSlug) {
      //console.log("save compare", settingsData.pageSlug, pageInfo)
      if(!pageInfo.page_id){
        //savePage("save")
      }else{
        //savePage("update")   
      }      
    }
    console.log("SAVE", pageInfo)
  }, [pageInfo.settings.pageName, pageInfo.settings.pageSlug]);
*/

  useEffect(() => {
    //console.log("pageInfo.settings.pageType", pageInfo.settings.pageType)
    //console.log("settingsData.pageType", settingsData.pageType)

    //console.log("settingsData.pageName", settingsData.pageName)
    let pageName = settingsData.pageName     
    let pageSlug = settingsData.pageSlug
      
    if(!pageInfo.page_id) {
      if(settingsData.pageType=='dynamic'){
        if(pageName=='') pageName = pages.length > 0 ? `New Page - ${pages.length + 1}` : "/new-page-1";        
        pageSlug =
          pages.length > 0 ? `/${title2Slug(pageName)}/:id` : "/new-page-1/:id";  
      }else{
        if(pageName=='') pageName = pages.length > 0 ? `New Page - ${pages.length + 1}` : "Home";        
        pageSlug =
          pages.length > 0 ? `/${title2Slug(pageName)}` : "";     
        
      }
      setSettingsData((data) => ({ ...data, ...{ pageName, pageSlug } }));
      /*dispatch({
        type: CREATE_NEW_PAGE,
        data: { pageName, pageSlug },
      });*/
      //console.log("{ pageName, pageSlug }", { pageName, pageSlug })
    }else{
      //console.log("{ pageName, pageSlug }",'old page')
    }
   
  }, [settingsData.pageType]);

  const title2Slug = (title) =>{ 
    if(!title) return title
    return title
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
  }




  const savePage2 = (action='') => {
    console.log(action, pageInfo)    
    let pageInfoData = set(cloneDeep(pageInfo), 'settings', settingsData);
    saveProject({ id: project_id, pageInfo:pageInfoData }).then(async (resp) => {
      if (resp.success) {
        toast.success("Page successfully updated");
        fetchPageData({ project_id, page_id: resp.data[0].page_id });
        const { data } = await getProjectInfo(project_id);
        dispatch({ type: UPDATE_PROJECT_INFO, data });
        dispatch({ type: SELECT_PAGE_ID, id: resp.data[0].page_id });
        dispatch({ type: RESET_POPUP });
      }
    });    
  };

  const fetchPageData = async ({ project_id, page_id }) => {
    const json = await getPageJSONData({
      project_id,
      page_id,
    });
    if (json.success) {
      dispatch({ type: UPDATE_PROJECT_PAGE, data: json.data });
    } else {
      toast.error("Error while fetching page info");
    }
  };

  return (
    <div  className={twdClass("flex flex-col h-full")}>
      <div className={`pages-settings-layout  ${twdClass("px-6 flex-grow")}`}>        
        <div className={twdClass("flex items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>Page Type</label>
          <div className={twdClass("flex-1")}>
            <ToggleBtn
              value={settingsData.pageType === "dynamic"}
              falseLabel="Static Page"
              trueLabel="Dynamic Page"
              updateValue={(value) => {
                updateSettingsData("pageType", value ? "static" : "dynamic");
              }}
            />
          </div>
        </div>

        <div className={twdClass("flex items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>Page Name</label>
          <div className={twdClass("flex-1")}>
            <input
              type="text"
              placeholder="About"
              value={settingsData.pageName}
              className={twdClass(INPUT_TEXT)}
              onChange={(e) => {
                updateSettingsData("pageName", e.target.value);
              }}
            />
          </div>
        </div>

        
        <div className={twdClass("flex items-center mb-2")}>
          <label className={twdClass("w-40 text-sm")}>
            Slug
            <span className={twdClass("ml-2 text-xs text-gray-400")}>
              (Example:
              {settingsData.pageType === "dynamic" ? " /:id" : " /about"}
              )
            </span>
          </label>
          <div className={twdClass("flex-1")}>
            <input
              type="text"
              placeholder={`${
                settingsData.pageType === "dynamic" ? "/somepath/:id or /:id" : "/about"
              }`}
              className={twdClass(INPUT_TEXT)}
              value={settingsData.pageSlug}
              onChange={(e) => {
                updateSettingsData("pageSlug", e.target.value);
              }}
            />
          </div>
        </div>
        {settingsData.pageType === "dynamic" && (
          <DynamicConnectionType
            connectionType={connectionType}
            updateSettingsData={updateSettingsData}
            setConnectionType={setConnectionType}
          />
        )}
        {settingsData.pageType === "dynamic" &&
          connectionType === "googleSheet" && (
            <PagesGoogleSheetInfo
              project_id={project_id}
              settingsData={settingsData}
              pageInfo={pageInfo}
              connections={connections}
              updateSettingsData={updateSettingsData}
            />
          )}
        {settingsData.pageType === "dynamic" &&
          connectionType === "supabase" && (
            <PageSupabasePanel
              connections={connections}
              project_id={project_id}
              settingsData={settingsData}
              pageInfo={pageInfo}
              updateSettingsData={updateSettingsData}
            />
          )}
      </div>

      <div className={twdClass("mt-4 px-4 py-4 text-right flex justify-end")}>
        <Button
          type="default"  color="primary" className={''} 
          onClick={() => {
            
            if(pageInfo && pageInfo.page_id){
              //console.log("update Page Action", settingsData)
              /*dispatch({
                type: UPDATE_PAGE_SETTINGS,
                data: settingsData,
              });   
              dispatch({
                type: RESET_POPUP,
              }); */
              saveSettings("update", settingsData)              
            }else{
              //console.log("Add Page Action", settingsData)
              /*dispatch({
                type: CREATE_NEW_PAGE,
                data: settingsData,
              });*/
              saveSettings("save", settingsData) 
            }           
            /*dispatch({
              type: RESET_POPUP,
            });*/
          }}
        >
          {(pageInfo && pageInfo.page_id) ? 'Update' : 'Add'} Page
        </Button>
      </div>
    </div>
  );
};

export default PagesSettingsPanel;

const DynamicConnectionType = ({ connectionType, setConnectionType }) => {
  return (
    <div className={twdClass("flex items-center mb-2")}>
      <label className={twdClass("w-40 text-sm")}>
        Connection Type
        <span className={twdClass("text-red-600")}>*</span>
      </label>
      <div className={twdClass("flex-1")}>
        <select
          className={twdClass(INPUT_TEXT)}
          onChange={(e) => {
            setConnectionType(e.target.value);
          }}
          value={connectionType}
        >
          <option value="">Select</option>
          {CONNECTIONS_METADATA.map((item) => (
            <option value={item.key}>{item.value}</option>
          ))}
        </select>
      </div>
    </div>
  );
};
