import { set, get } from "lodash";
import { twdClass } from "../../constants/tailwind.constants";
import { StyleBtn, SettingsBtn, CollapseBtn} from "../ui/presets";
import {
  UPDATE_BLOCK_DATA,
  UPDATE_STYLE_ID,
  UPDATE_STYLE_PART,  
  UPDATE_ADVANCED_ID
} from "../../constants/action.constants";


import { useDispatch } from "react-redux";
import { RIGHT_PANEL_TOGGEL } from "../../constants/action.constants";
import { Button} from "../ui/com_ui";

import RenderElements from "./RenderElements";
import { useState } from "react";
import ListComponent from "./ListComponent";

import GenerateGroup from "./GenerateGroup"
import {hideshowElement} from "./elements/util";

const SettingsDetails = ({ sectionData, sectionElem, blocks }) => {
  const { sectionName } = sectionElem;
  const { metaDataId } = sectionData;

  const dispatch = useDispatch();

  const updateElementValue = (to, value) => {
    let blockData = set(sectionData, to, value);
    blocks = blocks.map((item) => {
      if (item.metaDataId === metaDataId) {
        return { ...blockData };
      }
      return item;
    });

    dispatch({
      type: UPDATE_BLOCK_DATA,
      data: blocks,
    });
  };
  const inputArgs = { display_label: true, inputStyle: 2 };
  return (
    <>
      <div className={twdClass("px-3 py-3 flex items-center")}>
        <div className={twdClass("flex-grow flex items-center")}>
          <div            
            className={twdClass("mr-4")}
          >
            <img src={'/assets/images/space-horizontal.svg'}/>
          </div>
          <div>
            <div className={twdClass("text-gray-900 text-lg font-semibold")}>{sectionName}</div>
            <div className={twdClass("text-xs")}>Section</div>
          </div>
        </div>
        {/*<div
          className={twdClass("cursor-pointer")}
          onClick={() => dispatch({ type: UPDATE_STYLE_ID, id: 0 })}
        >
          <img alt="" src="/assets/images/icon_arrow_right.png" />
        </div>*/}
        <SettingsBtn  onClick={() => dispatch({ type: UPDATE_ADVANCED_ID, id: 0 })} className={"mr-2"}/>
        <StyleBtn  onClick={() =>{ 
          dispatch({ type: UPDATE_STYLE_ID, id: 0 })
          dispatch({ type: UPDATE_STYLE_PART, part: null });
        }}/>
      </div>
      <div className={twdClass("px-3 py-3")}>
        {sectionElem.elements.map((item) => {
          if(item.inputType=="group"){
             return (
              <GenerateGroup
                element={item}
                data={sectionData}
                updateElementValue={updateElementValue}
                inputArgs={inputArgs}
              />
            );
          }
          return (
            <GenerateElements
              element={item}
              data={sectionData}
              updateElementValue={updateElementValue}
              inputArgs={inputArgs}
            />
          );
        })}
      </div>
    </>
  );
};

export default SettingsDetails;



const GE_CLS_1 = twdClass(
  "bg-gray-50 px-1 text-sm border border-white hover:bg-gray-200 mb-1 rounded-xl"
);
const GE_CLS_1b = twdClass(
  "bg-white px-1 pb-2 text-sm  border border-white border-gray-200 hover:border-gray-300 mb-1 rounded-xl"
);
const GE_CLS_2 = twdClass("pl-3 py-0 flex items-center cursor-pointer");
const GE_CLS_3 = twdClass("text-gray-800 font-semibold flex-grow flex items-center");
const GE_CLS_4 = twdClass("bg-white px-0 py-2");
const GE_CLS_4b = twdClass("bg-white px-0 py-2 hidden");



const GenerateElements = ({ element, data, updateElementValue, inputArgs }) => {
  const { hideBasedElemVal, showBasedElemVal } = element;
  const [show, setShow] = useState(true);

  /*let hideElement = false;
  if (hideBasedElemVal) {
    const { elem, value } = hideBasedElemVal;
    hideElement = get(data, elem) === value;
  }
  if (showBasedElemVal) {
    const { elem, value } = showBasedElemVal;
    hideElement = get(data, elem) !== value;
  }*/
  let hideElement = hideshowElement({data, element}) 
  if (hideElement) {
    return <></>;
  }
  return (
    <GenerateEleHeadWrpapper
      element={element}
      data={data}
      updateElementValue={updateElementValue}
      setShow={setShow}
      show={show}
      inputArgs={inputArgs}
    />
  );
};

const GenerateEleHeadWrpapper = ({
  element,
  data,
  updateElementValue,
  show,
  setShow,
  inputArgs,
}) => {
  const dispatch = useDispatch();
  const { label, hasStyleProps, styleElementId, inputType } = element;

  if (inputType === "list" || inputType === "custom") {
    return (
      <RenderElements
        element={element}
        data={data}
        updateElementValue={updateElementValue}
        inputArgs={inputArgs}
      />
    );
  }
  return (
    <div className={show ? GE_CLS_1b : GE_CLS_1}>
      <div className={GE_CLS_2} onClick={() => setShow(!show)}>
        <div className={GE_CLS_3}>
          <img width="20px" className={twdClass("mr-2")} src={'/assets/images/align-horizontal-center-02.svg'}/>
          {label}
        </div>        
        {/*hasStyleProps && (
          <div
            className={twdClass("cursor-pointer")}
            onClick={() => {
              dispatch({ type: UPDATE_STYLE_ID, id: styleElementId });
            }}
          >
            <img alt="" src="/assets/images/icon_arrow_right.png?v=1" />
          </div>
        )*/}
        {hasStyleProps && (         
          <StyleBtn onClick={() => {
            dispatch({ type: UPDATE_STYLE_ID, id: styleElementId });
            dispatch({ type: UPDATE_STYLE_PART, part: null });
          }}/>
        )}
        <CollapseBtn className={'ml-1'} isOpen={show}/>
      </div>
      <div className={show ? GE_CLS_4 : GE_CLS_4b}>
        <RenderElements
          element={element}
          data={data}
          updateElementValue={updateElementValue}
          inputArgs={inputArgs}
        />
      </div>
    </div>
  );
};
