import { getDefaultFields } from "../common/common.fields";

export const BUTTONS_ELEMENT_FORM = {
  sectionName: "Buttons Section",
  elements: [
    {
      inputType: "list",
      label: "Buttons",
      hasStyleProps: true,
      styleElementId: 1,
      updateTo: "buttons.list",
      mockElements: {
        hasStyleProps: true,
        styleElementId: 2,
        elements: [
          {
            inputType: "text",
            label: "Button",
            defaultValue: "Click Here",
            updateTo: "buttons.list[i].text",
            extraPropUpdateTo: "buttons.list[i]",
            extraPropValues: {
              text: "Click Here",
              styleProps: {
                color: "#ffffff",
                "border-color": "#3b82f680",
              },
            },
            hasStyleProps: false,
          },
          {
            inputType: "text",
            label: "URL",
            defaultValue: "",
            updateTo: "buttons.list[i].url",
            hasStyleProps: false,
          },          
          {
            inputType: "select",
            label: "Open In",
            defaultValue: "sametab",
            updateTo: "buttons.list[i].openType",
            hasStyleProps: false,
            options: [
              { value: "sametab", display: "Same Tab" },
              { value: "newtab", display: "New Tab" },
            ],
          },
          {
            inputType: "group",
            label: "Advanced",
            initShow:false,
            elements: [
              {
                inputType: "text",
                label: "Button ID",
                defaultValue: "",
                updateTo: "buttons.list[i].button_id",
                hasStyleProps: false,
              }, 
              {
                inputType: "text",
                label: "Alt Text",
                defaultValue: "",
                updateTo: "buttons.list[i].alt",
                hasStyleProps: false,
              },             
            ]
          },

        ],
      },
      elements: [],
    },
  ],
  styleElements: [
    {
      styleElementId: 0,
      label: "Button section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "styleProps",
          fields: ["backgroundcolor", "backgroundimage", "padding", "margin", "editall"],
        }),
      ],
    },
    {
      styleElementId: 1,
      label: "Button section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "buttons.styleProps",
          fields: [
            "backgroundcolor",
            "backgroundimage",
            "padding",
            "margin",
            "justify-align",
            "editall"
          ],
        }),
      ],
    },
    {
      styleElementId: 2,
      label: "Button section",
      elements: [
        ...getDefaultFields({
          type: "TEXT",
          updateTo: "buttons.list[i].styleProps",
          fields: [
            "color",
            "fontfamily",
            "fontweight",
            "fontsize",
            "backgroundcolor",
            "padding",
            "margin",
            "bordercolor",
            "borderradius",
            "editall"
          ],
        }),
      ],
    },
  ],
};
