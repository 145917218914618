import {
  ADD_BLOCK_TO_PAGE,
  RIGHT_PANEL_TOGGEL,
  SELECT_BLOCK_ONCLICK,
  UPDATE_BLOCK_DATA,
  UPDATE_PROJECT_INFO,
  RESET_AUTOSAVE,
  RESET_PROJECT_DETAILS,
  UPDATE_PROJECT_PAGE,
  UPDATE_STYLE_ID,
  UPDATE_STYLE_PART,
  UPDATE_ADVANCED_ID,
  UPDATE_LIST_INDEX,
  UPDATE_PAGE_SETTINGS,
  CREATE_NEW_PAGE,
  UPDATED_CONNECTIONS,
  SELECT_PAGE_ID,
  UPADTE_PAGES_LIST,
  UPDATE_SITE_SETTINGS,
  UPDATE_PREVIEW_TYPE,
  RESET_PREVIEW_TYPE,
} from "../constants/action.constants";
import { cloneDeep, set } from "lodash";

const initView = {
  previewType: "desktop",
};

export const studioView = (state = initView, action) => {
  switch (action.type) {
    case UPDATE_PREVIEW_TYPE:
      return { ...state, previewType: action.data };
    case RESET_PREVIEW_TYPE:
      return { ...initView };
    default:
      return state;
  }
};

const initSettings = {
  settings: {
    pageType: "static",
    pageSlug: "",
    pageName: "Home",
    paramsValues: [],
    connectionId: "",
    connectionTable: "",
    googleSheetName: "",
    slugParamColumn: "",
    metadata: {
      standard: {},
      opengraph: {},
      twitter: {},
    },
  },
};

const initSiteSettings = {
  metadata: {
    standard: {},
    opengraph: {},
    twitter: {},
  },
  externalJS: "",
  externalCSS: "",
  headTags: "",
};
const initAutoSave = {
  newActions:0,
};

const initialState = {
  projectPref:{
    autoSave:true,
  },
  autoSave:initAutoSave,
  saveTimestamp: null,
  openRightPanel: true,
  pageInfo: {
    ...cloneDeep(initSettings),
    page_id: "",
    blocks: [],
  },
  selectedBlock: null,
  styleElementId: null,
  styleElementPart: null,
  advancedElementId:null,
  selectedPageId: null,
  listIndex: null,
  sitesettings: cloneDeep(initSiteSettings),
  projectData: {
    pages: [],
    connections: [],
  },
};

export const studioDetails = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case UPDATE_PROJECT_INFO:
      return { ...state, autoSave:cloneDeep(initAutoSave), projectData: action.data };
    case UPDATE_PROJECT_PAGE: {
      return { ...state, pageInfo: action.data };
    }    
    case RIGHT_PANEL_TOGGEL: {
      return { ...state, openRightPanel: action.data };
    }
    case ADD_BLOCK_TO_PAGE: {
      const pageInfo = cloneDeep(state.pageInfo);
      pageInfo.blocks.push(action.data);
      return { ...state, pageInfo };
    }
    case SELECT_BLOCK_ONCLICK: {
      return {
        ...state,
        selectedBlock: action.data,
        openRightPanel: true,
        styleElementId: null,
        advancedElementId: null,
        listIndex: null,
      };
    }
    case UPDATE_BLOCK_DATA: { 
      let autoSaveOrig={...state.autoSave}  
      //console.log("UPDATE_BLOCK_DATA before", autoSaveOrig)
      let autoSave={...state.autoSave, newActions:autoSaveOrig.newActions+1, fasty:autoSaveOrig.newActions+1}   
      //console.log("autoSave", autoSave)   
      //if(!state.pageInfo.orig_blocks) state.pageInfo.orig_blocks = state.pageInfo.blocks 
      if(!state.pageInfo.backupSaved) {
        console.log("BACK SAVED", state.pageInfo.backupSaved)
        state.pageInfo.orig_blocks = state.pageInfo.blocks 
        state.pageInfo.backupSaved = true 
      }
      let ret={ ...state, autoSave, pageInfo: { ...state.pageInfo, blocks: action.data } }
      //let ret2=autoSave
      
      //console.log("UPDATE_BLOCK_DATA", autoSave)
      return ret;
    }
    case RESET_AUTOSAVE:
      return { ...state, autoSave:cloneDeep(initAutoSave)};
    case RESET_PROJECT_DETAILS:
      return cloneDeep(initialState);
    case UPDATE_STYLE_ID:
      return { ...state, styleElementId: action.id };
    case UPDATE_STYLE_PART:
      return { ...state, styleElementPart: action.part };
    case UPDATE_ADVANCED_ID:
      return { ...state, advancedElementId: action.id };
    case UPDATE_LIST_INDEX:
      return { ...state, listIndex: action.id };
    case UPDATE_PAGE_SETTINGS: {
      const pageInfo = cloneDeep(state.pageInfo);
      pageInfo.settings = { ...pageInfo.settings, ...action.data };
      //console.log("UPDATE_PAGE_SETTINGS", pageInfo)
      return { ...state, pageInfo };
    }
    case UPADTE_PAGES_LIST: {
      return {
        ...state,
        projectData: {
          ...state.projectData,
          pages: action.data,
        },
      };
    }
    case SELECT_PAGE_ID: {
      return { ...state, selectedPageId: action.id };
    }
    case CREATE_NEW_PAGE: {
      const { pageName, pageSlug } = action.data;
      const intdata = {
        ...cloneDeep(initialState),
        projectData: state.projectData,
      };
      let updata = set(intdata, "pageInfo.settings.pageName", pageName);
      updata = set(intdata, "pageInfo.settings.pageSlug", pageSlug);
      //console.log("CREATE_NEW_PAGE", updata)
      return updata;
    }
    case UPDATED_CONNECTIONS: {
      return {
        ...state,
        projectData: {
          ...state.projectData,
          connections: action.data,
        },
      };
    }
    case UPDATE_SITE_SETTINGS: {
      return {
        ...state,
        sitesettings: action.data,
      };
    }
    default:
      return state;
  }
};
